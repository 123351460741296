import React, { useEffect } from "react"
import { Select } from "antd"

const { Option } = Select

interface Props {
  inputs: {
    details: {
      whoToBuy: string
      propertyType: string
      dependents: number
    }
    income: {
      baseIncome: number
      baseIncomePeriod: string
      secondIncome: number
      secondIncomePeriod: string
      rentalIncome: number
      rentalIncomePeriod: string
    }
    expenses: {
      homeLoans: boolean | null
      loansBalance: number
      repayments: number
      repaymentsPeriod: string
      creditCards: boolean | null
      creditCardsLimit: number
      otherLoans: boolean | null
      otherLoansLimit: number
      useLivingCosts: boolean | null
      livingCosts: number
      livingCostsPeriod: string
    }
    should_show: boolean
  }
  setInputs: any
}

const DetailsInputs: React.FC<Props> = ({ inputs, setInputs }) => {
  return (
    <div className="input-block">
      <h3>Your Details</h3>

      <p>Who wants to buy a home?</p>
      <div className="inline">
        <button
          onClick={() =>
            setInputs({
              ...inputs,
              details: { ...inputs.details, whoToBuy: "just me" },
              income: { ...inputs.income, secondIncome: 0 },
              should_show: false,
            })
          }
          className={`theme-btn block red ${
            inputs.details.whoToBuy === "just me" && "active"
          }`}
        >
          Just me
        </button>
        <button
          onClick={() =>
            setInputs({
              ...inputs,
              details: { ...inputs.details, whoToBuy: "joint" },
              should_show: false,
            })
          }
          className={`theme-btn block red ${
            inputs.details.whoToBuy === "joint" && "active"
          }`}
        >
          Joint
        </button>
      </div>

      <p>What type of property are you looking for?</p>
      <div className="inline">
        <button
          onClick={() =>
            setInputs({
              ...inputs,
              details: { ...inputs.details, propertyType: "a home to live in" },
              income: {
                ...inputs.income,
                rentalIncome: 0,
              },
              should_show: false,
            })
          }
          className={`theme-btn block red ${
            inputs.details.propertyType === "a home to live in" && "active"
          }`}
        >
          A home to live in
        </button>
        <button
          onClick={() =>
            setInputs({
              ...inputs,
              details: {
                ...inputs.details,
                propertyType: "an investment property",
              },
              should_show: false,
            })
          }
          className={`theme-btn block red ${
            inputs.details.propertyType === "an investment property" && "active"
          }`}
        >
          An investment property
        </button>
      </div>

      <p>How many dependents do you have?(18 years or younger)</p>

      <div>
        <Select
          value={inputs.details.dependents}
          dropdownMatchSelectWidth={false}
          bordered={true}
          onChange={value => {
            setInputs({
              ...inputs,
              details: { ...inputs.details, dependents: value },
              should_show: false,
            })
          }}
        >
          <Option value="0">0</Option>
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
        </Select>
      </div>
    </div>
  )
}

export default DetailsInputs
