import { useAnonomousLogin, useSetDoc } from "@lib/firebase";
import { anyValueToNumber } from "@util/helpers";
import { useGetIP } from "@util/hooks";
import { Button, P } from "@util/standard";
import React from "react";

interface Props {
  inputs: {
    details: {
      whoToBuy: string;
      propertyType: string;
      dependents: number;
    };
    income: {
      baseIncome: number;
      baseIncomePeriod: string;
      secondIncome: number;
      secondIncomePeriod: string;
      rentalIncome: number;
      rentalIncomePeriod: string;
    };
    expenses: {
      homeLoans: boolean | null;
      loansBalance: number;
      repayments: number;
      repaymentsPeriod: string;
      creditCards: boolean | null;
      creditCardsLimit: number;
      otherLoans: boolean | null;
      otherLoansLimit: number;
      useLivingCosts: boolean | null;
      livingCosts: number;
      livingCostsPeriod: string;
    };
    should_show: boolean;
  };
  setInputs: any;
}

const formatData = (inputs: Props["inputs"]) => {
  return {
    details: {
      dependents: anyValueToNumber(inputs.details.dependents),
      whoToBuy: inputs.details.whoToBuy ?? "",
      propertyType: inputs.details.propertyType ?? "",
    },
    expenses: {
      creditCards: Boolean(inputs.expenses.creditCards),
      creditCardsLimit: anyValueToNumber(inputs.expenses.creditCardsLimit),
      homeLoans: Boolean(inputs.expenses.homeLoans),
      livingCosts: anyValueToNumber(inputs.expenses.livingCosts),
      livingCostsPeriod: inputs.expenses.livingCostsPeriod ?? "",
      loansBalance: anyValueToNumber(inputs.expenses.loansBalance),
      otherLoans: Boolean(inputs.expenses.otherLoans),
      otherLoansLimit: anyValueToNumber(inputs.expenses.otherLoansLimit),
      repayments: anyValueToNumber(inputs.expenses.repayments),
      repaymentsPeriod: inputs.expenses.repaymentsPeriod ?? "",
      useLivingCosts: Boolean(inputs.expenses.useLivingCosts),
    },
    income: {
      baseIncome: anyValueToNumber(inputs.income.baseIncome),
      baseIncomePeriod: inputs.income.baseIncomePeriod ?? "",
      rentalIncome: anyValueToNumber(inputs.income.rentalIncome),
      rentalIncomePeriod: inputs.income.rentalIncomePeriod ?? "",
      secondIncome: anyValueToNumber(inputs.income.secondIncome),
      secondIncomePeriod: inputs.income.secondIncomePeriod ?? "",
    },
  };
};

const Submit = ({ inputs, setInputs }: Props) => {
  const ip = useGetIP();
  useAnonomousLogin();

  const handleClick = () => {
    setInputs({
      ...inputs,
      should_show: true,
    });

    useSetDoc("borrowing", {
      ...formatData(inputs),
      userAgent: window.navigator.userAgent,
      ipAddress: ip.ip,
      country: ip.country,
    });
  };

  return (
    <div>
      <Button onClick={handleClick}>Calculate borrowing amount</Button>
    </div>
  );
};

export default Submit;
