import React from 'react'
import { graphql, navigate, useStaticQuery } from "gatsby"
import ContentBlock from '../../shared/sub/contentBlock'
import WhatAndHowSection from "./whatAndHowStyle"

const WhatAndHow = () => {
  const {
    sanityCalculatorBorrowingPage: { howToApply },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorBorrowingPage {
        howToApply {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `)

  return (
    <WhatAndHowSection>
      <div className="disclaimer-content">
        <p>
          Please read our <span onClick={() => navigate('/website-disclaimer')}>Disclaimer Statement</span> for more information on these results.
        </p>
      </div>
      <ContentBlock blocks={howToApply._rawContent} />
    </WhatAndHowSection>
  )
}

export default WhatAndHow
