//A helper object for the borrow calculator functions

import { useStaticQuery, graphql } from "gatsby"

//1. Calculate Monthly
export const MonthlyAmount = yearlyAmount => {
  return yearlyAmount / 12
}

//2. Calculate Rental Income
export const RentalIncome = (monthlyRentalAmount, rentalIncomeScale) => {
  return monthlyRentalAmount * rentalIncomeScale
}

//3. Calculate Credit Card Repayments
export const CreditCardRepayments = (creditCardLimit, repaymentRatio) => {
  return creditCardLimit * repaymentRatio
}

//3. Calculate Other Loan Repayments
export const OtherLoanRepayments = (
  totalAmountOfOtherLoans,
  repaymentRatio
) => {
  return totalAmountOfOtherLoans * repaymentRatio
}

//4. Calculate Total Fixed Financial Commitments
export const TotalFixedFinancialComitments = (
  creditCardLimit: number,
  cardRepaymentRatio: number,
  totalAmountOfOtherLoans: number,
  loansRepaymentRatio: number,
  loansBalance: number,
  homeLoanRepaymentRatio: number
) => {
  var creditCardRepayments = creditCardLimit * cardRepaymentRatio
  var otherLoanRepayments = totalAmountOfOtherLoans * loansRepaymentRatio

  var homeLoanRepayments = loansBalance * homeLoanRepaymentRatio
  if (homeLoanRepayments === NaN || homeLoanRepayments === 0) {
    return creditCardRepayments + otherLoanRepayments
  }
  return creditCardRepayments + otherLoanRepayments + homeLoanRepayments
}

//5. Calculate Living costs
export const TotalLivingCosts = (
  numberOfAdults: number,
  singleLivingCosts: number,
  coupleLivingCosts: number,
  calculatedAdultLivingCost: number,
  dependantCosts: number,
  totalCombinedGrossIncome: number
) => {
  var totalLivingCosts = 0
  if (calculatedAdultLivingCost < 1) {
    if (numberOfAdults === 1) {
      totalLivingCosts = singleLivingCosts
    }
    if (numberOfAdults === 2) {
      totalLivingCosts = coupleLivingCosts
    }
  } else {
    totalLivingCosts = calculatedAdultLivingCost
  }

  var totalDependantCosts = 0
  if (calculatedAdultLivingCost < 1) {
    totalDependantCosts = dependantCosts
  }

  var gmiScalingFactor = (totalCombinedGrossIncome / 12) * 0.053

  return totalLivingCosts + totalDependantCosts + gmiScalingFactor
}

//6. Calculate PV
export const PV = (rate, periods, payment, future, type) => {
  // Initialize type
  var type = typeof type === "undefined" ? 0 : type

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  rate = eval(rate)
  periods = eval(periods)

  // Return present value
  if (rate === 0) {
    return -payment * periods - future
  } else {
    return (
      (((1 - Math.pow(1 + rate, periods)) / rate) *
        payment *
        (1 + rate * type) -
        future) /
      Math.pow(1 + rate, periods)
    )
  }
}
