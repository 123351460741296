import React from "react"
import { InputNumber, Select } from "antd"
import Submit from "./submit"

const { Option } = Select

interface Props {
  inputs: {
    details: {
      whoToBuy: string
      propertyType: string
      dependents: number
    }
    income: {
      baseIncome: number
      baseIncomePeriod: string
      secondIncome: number
      secondIncomePeriod: string
      rentalIncome: number
      rentalIncomePeriod: string
    }
    expenses: {
      homeLoans: boolean | null
      loansBalance: number
      repayments: number
      repaymentsPeriod: string
      creditCards: boolean | null
      creditCardsLimit: number
      otherLoans: boolean | null
      otherLoansLimit: number
      useLivingCosts: boolean | null
      livingCosts: number
      livingCostsPeriod: string
    }
    should_show: boolean
  }
  setInputs: any
}

const ECInputs: React.FC<Props> = ({ inputs, setInputs }) => {
  return (
    <div className="input-block">
      <h3>Your Expenses and Commitments</h3>

      <p>Do you have any home loans?</p>
      <div className="inline">
        <button
          className={`theme-btn block red ${
            inputs.expenses.homeLoans === true && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: { ...inputs.expenses, homeLoans: true },
            })
          }
        >
          Yes
        </button>
        <button
          className={`theme-btn block red ${
            inputs.expenses.homeLoans === false && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: {
                ...inputs.expenses,
                homeLoans: false,
                loansBalance: 0,
                repayments: 0,
              },
            })
          }
        >
          No
        </button>
      </div>

      {inputs.expenses.homeLoans && (
        <div className="following-model">
          <p>Loan/s balance</p>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value =>
              value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "")
            }
            min={0}
            value={inputs.expenses.loansBalance}
            onChange={value =>
              setInputs({
                ...inputs,
                should_show: false,
                expenses: { ...inputs.expenses, loansBalance: value },
              })
            }
            step={1000}
            style={{ width: "130px" }}
          />

          <p>Repayments</p>
          <div className="inline">
            <InputNumber
              formatter={value =>
                `$ ${value}`
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .replace(/\s/g, "")
              }
              parser={value =>
                value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "")
              }
              min={0}
              value={inputs.expenses.repayments}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  expenses: { ...inputs.expenses, repayments: value },
                })
              }
              step={1000}
              style={{ width: "130px" }}
            />
            <Select
              defaultValue="per year"
              dropdownMatchSelectWidth={false}
              value={inputs.expenses.repaymentsPeriod}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  expenses: { ...inputs.expenses, repaymentsPeriod: value },
                })
              }
            >
              <Option value="per week">Per week</Option>
              <Option value="per fortnight">Per fornight</Option>
              <Option value="per month">Per month</Option>
              <Option value="per quarter">Per quarter</Option>
              <Option value="per year">Per year</Option>
            </Select>
          </div>
        </div>
      )}

      <p>Do you have any credit cards?</p>
      <div className="inline">
        <button
          className={`theme-btn block red ${
            inputs.expenses.creditCards === true && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: { ...inputs.expenses, creditCards: true },
            })
          }
        >
          Yes
        </button>
        <button
          className={`theme-btn block red ${
            inputs.expenses.creditCards === false && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: {
                ...inputs.expenses,
                creditCards: false,
                creditCardsLimit: 0,
              },
            })
          }
        >
          No
        </button>
      </div>

      {inputs.expenses.creditCards && (
        <div className="following-model">
          <p>Limit</p>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value =>
              value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "")
            }
            min={0}
            value={inputs.expenses.creditCardsLimit}
            onChange={value =>
              setInputs({
                ...inputs,
                should_show: false,
                expenses: { ...inputs.expenses, creditCardsLimit: value },
              })
            }
            step={1000}
            style={{ width: "130px" }}
          />
        </div>
      )}

      <p>Any other loans/debt?</p>
      <div className="inline">
        <button
          className={`theme-btn block red ${
            inputs.expenses.otherLoans === true && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: { ...inputs.expenses, otherLoans: true },
            })
          }
        >
          Yes
        </button>
        <button
          className={`theme-btn block red ${
            inputs.expenses.otherLoans === false && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: {
                ...inputs.expenses,
                otherLoans: false,
                otherLoansLimit: 0,
              },
            })
          }
        >
          No
        </button>
      </div>

      {inputs.expenses.otherLoans && (
        <div className="following-model">
          <p>Limit</p>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value =>
              value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "")
            }
            min={0}
            value={inputs.expenses.otherLoansLimit}
            onChange={value =>
              setInputs({
                ...inputs,
                should_show: false,
                expenses: { ...inputs.expenses, otherLoansLimit: value },
              })
            }
            step={1000}
            style={{ width: "130px" }}
          />
        </div>
      )}

      <p>
        Would you like to use the average living costs for your typical
        household?
      </p>
      <div className="inline">
        <button
          className={`theme-btn block red ${
            inputs.expenses.useLivingCosts === false && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: {
                ...inputs.expenses,
                useLivingCosts: false,
                livingCosts: 0,
              },
            })
          }
        >
          Yes
        </button>
        <button
          className={`theme-btn block red ${
            inputs.expenses.useLivingCosts === true && "active"
          }`}
          onClick={() =>
            setInputs({
              ...inputs,
              should_show: false,
              expenses: {
                ...inputs.expenses,
                useLivingCosts: true,
              },
            })
          }
        >
          No, I'll enter my own
        </button>
      </div>

      {inputs.expenses.useLivingCosts && (
        <div className="following-model">
          <p>Living Costs</p>
          <div className="inline">
            <InputNumber
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value =>
                value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "")
              }
              min={0}
              value={inputs.expenses.livingCosts}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  expenses: { ...inputs.expenses, livingCosts: value },
                })
              }
              step={1000}
              style={{ width: "130px" }}
            />
            <Select
              defaultValue="per year"
              dropdownMatchSelectWidth={false}
              value={inputs.expenses.livingCostsPeriod}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  expenses: { ...inputs.expenses, livingCostsPeriod: value },
                })
              }
            >
              <Option value="per week">Per week</Option>
              <Option value="per fortnight">Per fornight</Option>
              <Option value="per month">Per month</Option>
              <Option value="per quarter">Per quarter</Option>
              <Option value="per year">Per year</Option>
            </Select>
          </div>
        </div>
      )}
      <div className="submit">
        <Submit inputs={inputs} setInputs={setInputs} />
      </div>
    </div>
  )
}

export default ECInputs
