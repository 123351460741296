export const IncomeResult = grossIncome => {
  //Parse the gross income as a float
  var grossIncomeFloat = parseFloat(grossIncome)

  //Define the tax brackets
  var taxBracket1 = 14000
  var percentBracket1 = 0.105
  var taxBracket2 = 48000
  var percentBracket2 = 0.175
  var taxBracket3 = 70000
  var percentBracket3 = 0.3
  var taxBracketElse = 0.33

  var totalTaxableAmount = 0

  //Calculate taxable income
  switch (true) {
    case grossIncomeFloat > taxBracket3:
      //Work out taxable payment on anything over $70,000
      totalTaxableAmount =
        (grossIncomeFloat - taxBracket3) * taxBracketElse +
        (taxBracket3 - taxBracket2) * percentBracket3 +
        (taxBracket2 - taxBracket1) * percentBracket2 +
        taxBracket1 * percentBracket1
      break

    case grossIncomeFloat > taxBracket2:
      //Work out taxable payment on anything over $70,000
      totalTaxableAmount =
        (grossIncomeFloat - taxBracket2) * percentBracket3 +
        (taxBracket2 - taxBracket1) * percentBracket2 +
        taxBracket1 * percentBracket1
      break

    case grossIncomeFloat > taxBracket1:
      //Work out taxable payment on anything over $70,000
      totalTaxableAmount =
        (grossIncomeFloat - taxBracket1) * percentBracket2 +
        taxBracket1 * percentBracket1
      break

    case grossIncomeFloat < taxBracket1:
      //Work out taxable payment on anything over $70,000
      totalTaxableAmount = grossIncomeFloat * percentBracket1
      break

    default:
      break
  }

  //Calculate ACC Levy
  var accUpperThreshold = 139911
  var accLevy = 0
  var accChecksum = 0.0139

  if (grossIncomeFloat > accUpperThreshold) {
    accLevy = accUpperThreshold * accChecksum
  } else {
    accLevy = grossIncomeFloat * accChecksum
  }

  var totalTaxesAndLevis = totalTaxableAmount + accLevy
  var netIncome = grossIncomeFloat - totalTaxesAndLevis

  return { totalTaxesAndLevis, netIncome }
}
