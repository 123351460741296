import React, { useState } from "react"
import DetailsInputs from "./inputs/detailsInputs"
import IncomeInputs from "./inputs/incomeInputs"
import ExpensesCommitmentsInput from "./inputs/expensesCommitmentsInputs"
import Result from "./results/result"

import CalculatorSection from "./calculatorStyle"
import InputBlocks from "./inputs/inputsStyle"
import Submit from "./inputs/submit"

const Calculator = () => {
  const [inputs, setInputs] = useState({
    details: {
      whoToBuy: "",
      propertyType: "",
      dependents: 0,
    },
    income: {
      baseIncome: 0,
      baseIncomePeriod: "per year",
      secondIncome: 0,
      secondIncomePeriod: "per year",
      rentalIncome: 0,
      rentalIncomePeriod: "per year",
    },
    expenses: {
      homeLoans: null,
      loansBalance: 0,
      repayments: 0,
      repaymentsPeriod: "per year",
      creditCards: null,
      creditCardsLimit: 0,
      otherLoans: null,
      otherLoansLimit: 0,
      useLivingCosts: null,
      livingCosts: 0,
      livingCostsPeriod: "per year",
    },
    should_show: false,
  })

  return (
    <CalculatorSection>
      <div className="container">
        <InputBlocks>
          <DetailsInputs inputs={inputs} setInputs={setInputs} />
          <IncomeInputs inputs={inputs} setInputs={setInputs} />
          <ExpensesCommitmentsInput inputs={inputs} setInputs={setInputs} />
        </InputBlocks>

        <Result inputs={inputs} />
      </div>
    </CalculatorSection>
  )
}

export default Calculator
