import React, { useEffect } from "react"
import { formatValue, parseValue } from "@util/helpers"
import { InputNumber, Select } from "antd"

const { Option } = Select
interface Props {
  inputs: {
    details: {
      whoToBuy: string
      propertyType: string
      dependents: number
    }
    income: {
      baseIncome: number
      baseIncomePeriod: string
      secondIncome: number
      secondIncomePeriod: string
      rentalIncome: number
      rentalIncomePeriod: string
    }
    expenses: {
      homeLoans: boolean | null
      loansBalance: number
      repayments: number
      repaymentsPeriod: string
      creditCards: boolean | null
      creditCardsLimit: number
      otherLoans: boolean | null
      otherLoansLimit: number
      useLivingCosts: boolean | null
      livingCosts: number
      livingCostsPeriod: string
    }
    should_show: boolean
  }
  setInputs: any
}

const IncomeInputs: React.FC<Props> = ({ inputs, setInputs }) => {
  return (
    <div className="input-block">
      <h3>Your Income</h3>

      <p>What is your base salary/wages?(before tax)</p>
      <div className="inline">
        <input
          value={formatValue(inputs.income.baseIncome)}
          onChange={value => {
            console.log(value.target.value)
            if (value.target.value == "$ ") return
            setInputs({
              ...inputs,
              should_show: false,
              income: {
                ...inputs.income,
                baseIncome:
                  value.target.value != "" &&
                  parseInt(parseValue(value.target.value)),
              },
            })
          }}
          min={0}
          step={1000}
          style={{ width: "130px" }}
        />

        <select
          value={inputs.income.baseIncomePeriod}
          onChange={value =>
            setInputs({
              ...inputs,
              should_show: false,
              income: {
                ...inputs.income,
                baseIncomePeriod: value.target.value,
              },
            })
          }
        >
          <option value="per week">Per week</option>
          <option value="per fortnight">Per fortnight</option>
          <option value="per month">Per month</option>
          <option value="per quarter">Per quarter</option>
          <option value="per year">Per year</option>
        </select>
      </div>

      {inputs.details.whoToBuy === "joint" && (
        <>
          <p>What is the second applicant's base salary/wages?</p>
          <div className="inline">
            <input
              value={formatValue(inputs.income.secondIncome)}
              onChange={value => {
                console.log(value.target.value)
                if (value.target.value == "$ ") return
                setInputs({
                  ...inputs,
                  should_show: false,
                  income: {
                    ...inputs.income,
                    secondIncome:
                      value.target.value != "" &&
                      parseInt(parseValue(value.target.value)),
                  },
                })
              }}
              min={0}
              step={1000}
              style={{ width: "130px" }}
            />

            <select
              value={inputs.income.secondIncomePeriod}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  income: { ...inputs.income, secondIncomePeriod: value },
                })
              }
            >
              <option value="per week">Per week</option>
              <option value="per fortnight">Per fortnight</option>
              <option value="per month">Per month</option>
              <option value="per quarter">Per quarter</option>
              <option value="per year">Per year</option>
            </select>
          </div>
        </>
      )}

      {inputs.details.propertyType === "an investment property" && (
        <>
          <p>Rental income estimation of this investment property</p>
          <div className="inline">
            <input
              min={0}
              value={formatValue(inputs.income.rentalIncome)}
              onChange={value => {
                console.log(value.target.value)
                if (value.target.value == "$ ") return
                setInputs({
                  ...inputs,
                  should_show: false,
                  income: {
                    ...inputs.income,
                    rentalIncome:
                      value.target.value != "" &&
                      parseInt(parseValue(value.target.value)),
                  },
                })
              }}
              step={1000}
              style={{ width: "130px" }}
            />

            <select
              defaultValue="per year"
              value={inputs.income.rentalIncomePeriod}
              onChange={value =>
                setInputs({
                  ...inputs,
                  should_show: false,
                  income: { ...inputs.income, rentalIncomePeriod: value },
                })
              }
            >
              <option value="per week">Per week</option>
              <option value="per fortnight">Per fortnight</option>
              <option value="per month">Per month</option>
              <option value="per quarter">Per quarter</option>
              <option value="per year">Per year</option>
            </select>
          </div>
        </>
      )}
    </div>
  )
}

export default IncomeInputs
