import styled from "styled-components"

export default styled.div`
  position: sticky;
  top: 9rem;
  background-color: var(--red);
  min-height: 30rem;
  height: fit-content;
  padding: 4rem;
  padding-bottom: 2rem;
  border-radius: 0.4rem;
  color: var(--white);

  @media only screen and (max-width: 976px) {
    padding: 3rem;
    padding-bottom: 1rem;
  }

  h3 {
    font-size: 2rem;
    font-family: medium;
    color: var(--white);
  }

  span.result {
    font-family: bold;
    font-size: 3rem;
  }

  p {
    margin: 3rem 0;
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20rem;

    .period {
      margin-bottom: 2rem;
      span.tag {
        display: inline-block;
        margin-right: 1rem;
      }

      .ant-select {
        border: 1px solid white;
        border-radius: 3px;

        .ant-select-selector {
          color: white;
        }

        .ant-select-arrow svg {
          fill: white !important;
        }
      }
    }

    span.payment {
      margin-bottom: 2rem;
      font-size: 3rem;
      font-family: bold;
    }
  }
`