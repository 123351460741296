import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import TextAndButton from "../../shared/textAndButtons"

const TextAndButtonBlock = () => {
  const {
    sanityCalculatorBorrowingPage: { textAndButton },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorBorrowingPage {
        textAndButton {
          text
          button {
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <div className="container">
      <TextAndButton button={textAndButton.button} text={textAndButton.text} midLine={true} />
    </div>
  )
}

export default TextAndButtonBlock
