import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Select } from "antd";
import { IncomeResult } from "../objects/incomeObject";
import {
  MonthlyAmount,
  RentalIncome,
  TotalLivingCosts,
  TotalFixedFinancialComitments,
  PV,
} from "../objects/helperObject";

import ResultBlock from "./resultsStyle";
import { useForm } from "antd/lib/form/Form";
import { Query } from "@graphql-types";
import { pushBorrowingCalculatorData } from "@util/dataLayer";

const { Option } = Select;

interface Props {
  inputs: {
    details: {
      whoToBuy: string;
      propertyType: string;
      dependents: string;
    };
    income: {
      baseIncome: number;
      baseIncomePeriod: string;
      secondIncome: number;
      secondIncomePeriod: string;
      rentalIncome: number;
      rentalIncomePeriod: string;
    };
    expenses: {
      homeLoans: boolean | null;
      loansBalance: number;
      repayments: number;
      repaymentsPeriod: string;
      creditCards: boolean | null;
      creditCardsLimit: number;
      otherLoans: boolean | null;
      otherLoansLimit: number;
      useLivingCosts: boolean | null;
      livingCosts: number;
      livingCostsPeriod: string;
    };
    should_show: boolean;
  };
}

const Result: React.FC<Props> = ({ inputs }) => {
  const [paymentPeriod, setPaymentPeriod] = useState("per week");

  const { sanityCalculatorBorrowingPage: data }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorBorrowingPage {
        ...sanityCalculatorBorrowingPage
      }
    }
  `);

  if (data == null) return null;

  const calculateDependantCost = () => {
    if (data) {
      const { dependantOneCosts, dependantTwoCosts, dependantThreeCosts } = data;
      const { dependents } = inputs && inputs.details;
      if (dependents === "1") {
        return dependantOneCosts;
      }
      if (dependents === "2") {
        return dependantTwoCosts;
      }
      if (dependents === "3") {
        return dependantThreeCosts;
      }
    }

    return 0;
  };

  const turnPeriodToYearly = (amount: number, period: string) => {
    switch (period) {
      case "per week":
        return amount * 52;
      case "per fortnight":
        return amount * 26;
      case "per month":
        return amount * 12;
      case "per quarter":
        return amount * 4;
      case "per year":
        return amount;
      default:
        return amount;
    }
  };

  const turnPeriodToMonthly = (amount: number, period: string) => {
    switch (period) {
      case "per week":
        return (amount * 52) / 12;
      case "per fortnight":
        return (amount * 26) / 12;
      case "per month":
        return amount;
      case "per quarter":
        return (amount * 4) / 12;
      case "per year":
        return amount / 12;
      default:
        return amount;
    }
  };

  const moneyFormat = (num: number) => {
    const rounded = Math.round(num * 100) / 100;

    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const baseIncome = IncomeResult(
    turnPeriodToYearly(inputs.income.baseIncome, inputs.income.baseIncomePeriod),
  );

  const secondIncome = IncomeResult(
    turnPeriodToYearly(inputs.income.secondIncome, inputs.income.secondIncomePeriod),
  );
  const rentalIncome = RentalIncome(
    turnPeriodToYearly(inputs.income.rentalIncome, inputs.income.rentalIncomePeriod),
    data.rentalIncomeScale,
  );

  const fixedFinancialComitments = TotalFixedFinancialComitments(
    inputs.expenses.creditCardsLimit,
    data.creditCardRepaymentRatio,
    inputs.expenses.otherLoansLimit,
    data.otherRepaymentRation,
    inputs.expenses.loansBalance,
    data.homeLoanRepaymentRatio,
  );

  const totalLivingCosts = TotalLivingCosts(
    inputs.details.whoToBuy === "just me" ? 1 : 2,
    data.singleLivingCosts,
    data.coupleLivingCosts,
    turnPeriodToMonthly(inputs.expenses.livingCosts, inputs.expenses.livingCostsPeriod),
    calculateDependantCost(),
    turnPeriodToYearly(inputs.income.baseIncome, inputs.income.baseIncomePeriod) +
      turnPeriodToYearly(inputs.income.secondIncome, inputs.income.secondIncomePeriod) +
      turnPeriodToYearly(inputs.income.rentalIncome, inputs.income.rentalIncomePeriod),
  );

  //Uncomited income = totalIncome - (totalLivingCosts + fixedFinancialComitments)
  const monthlyIncome = MonthlyAmount(baseIncome.netIncome + secondIncome.netIncome + rentalIncome);

  const uncommitedIncome =
    (monthlyIncome - (totalLivingCosts + fixedFinancialComitments)) * data.uncommitedPercent;

  const interestRate = data.testRate && data.testRate / 12;

  //payments = 360 over 30 years
  const potentialBorrow = PV(interestRate, 360, uncommitedIncome, 0, 0) * -1;

  const maxBorrow = potentialBorrow / 0.8;

  const repayment = (period: string, potentialBorrow: number) => {
    switch (period) {
      case "per week":
        return potentialBorrow / 30 / 52;
      case "per fortnight":
        return potentialBorrow / 30 / 26;
      case "per month":
        return potentialBorrow / 30 / 12;
      case "per quarter":
        return potentialBorrow / 30 / 4;
      case "per year":
        return potentialBorrow / 30;
    }
  };

  useEffect(() => {
    if (inputs.should_show) {
      const data = {
        income: monthlyIncome,
        expenses: fixedFinancialComitments,
        otherHomeLoans: inputs.expenses.loansBalance,
      };

      pushBorrowingCalculatorData(data);
    }
  }, [inputs.should_show]);

  return (
    <ResultBlock>
      {inputs.should_show ? (
        <>
          <h3>Results</h3>
          <p>Based on the information you provided, we estimate that you could borrow up to…</p>

          <span className="result">${potentialBorrow < 0 ? 0 : moneyFormat(potentialBorrow)}</span>

          {potentialBorrow > 0 && (
            <>
              {inputs.details.propertyType === "an investment property" ? (
                <p>
                  {`With a 35% deposit of $${moneyFormat(
                    maxBorrow * 0.3,
                  )}, you could afford a property up to
        $${moneyFormat(maxBorrow)}.`}
                </p>
              ) : (
                <p>
                  {`With a 20% deposit of $${moneyFormat(
                    maxBorrow * 0.2,
                  )}, you could afford a property up to
      $${moneyFormat(maxBorrow)}.`}
                </p>
              )}
            </>
          )}

          <div className="bottom">
            <div className="period">
              <span className="tag">Repayment per month</span>
              {/* <Select
                value={paymentPeriod}
                onChange={value => setPaymentPeriod(value)}
                dropdownMatchSelectWidth={false}
                bordered={true}
              >
                <Option value="per week">Per week</Option>
                <Option value="per fortnight">Per fortnight</Option>
                <Option value="per month">Per month</Option>
                <Option value="per quarter">Per quarter</Option>
                <Option value="per year">Per year</Option>
              </Select> */}
            </div>

            <span className="payment">
              ${potentialBorrow < 0 ? 0 : moneyFormat(repayment("per month", potentialBorrow))}
            </span>
          </div>
        </>
      ) : (
        <>
          <h3>Click calculate to display your results</h3>
        </>
      )}
    </ResultBlock>
  );
};

export default Result;
