import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import ContentBlock from "../../shared/sub/contentBlock"
import AboutCalculatorSection from "./aboutCalculatorStyle"


const AboutCalculator = () => {
  const {
    sanityCalculatorBorrowingPage: { aboutCalculator },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorBorrowingPage {
        aboutCalculator {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `)
  
  return (
    <AboutCalculatorSection>
      <ContentBlock blocks={aboutCalculator._rawContent} />
    </AboutCalculatorSection>
  )
}

export default AboutCalculator
