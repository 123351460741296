import React from "react"
import CalculatorsTabs from "../../components/calculators/calculatorsTabs"
import AboutCalculator from "../../components/calculators/borrowing/aboutCalculator"
import Calculator from "../../components/calculators/borrowing/calculator"
import WhatAndHow from "../../components/calculators/borrowing/whatAndHow"
import TextAndButton from "../../components/calculators/borrowing/textAndButton"
import BlogsSection from "../../components/shared/blogsSection"
import BorrowingSEO from "../../components/calculators/borrowing/borrowingSEO"
import { Header } from "@global"
import { useStaticQuery, graphql } from "gatsby"
import { Query } from "@graphql-types"
import { colors } from "@util/constants"

const Borrowing = () => {
  const { sanityCalculatorBorrowingPage }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorBorrowingPage {
        title
        categories {
          colorList
        }
      }
    }
  `)
  if (sanityCalculatorBorrowingPage == null) return null
  const { title, categories } = sanityCalculatorBorrowingPage

  const categoryColor = categories && categories[0] && categories[0].colorList

  const headerData = {
    headerColor: categoryColor ?? colors.maroon,
  }

  return (
    <div className="subpage-container">
      <BorrowingSEO />
      <Header title={title} headerData={headerData} />
      <CalculatorsTabs />
      <AboutCalculator />
      <Calculator />
      <WhatAndHow />
      <TextAndButton />
      <BlogsSection title="Blogs" />
    </div>
  )
}

export default Borrowing
