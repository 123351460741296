import styled from "styled-components"

export default styled.div`
  display: grid;
  grid-gap: 2rem;

  .input-block {
    padding: 4rem;
    background-color: var(--light-red);
    border-radius: 0.4rem;

    @media only screen and (max-width: 976px) {
      padding: 3rem;
    }

    h3 {
      font-size: 2rem;
      font-family: medium;
      color: var(--red);
    }

    p {
      color: var(--red);
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .following-model {
      border-bottom: 1px solid rgba(85, 30, 43, 0.2);
      padding-bottom: 2rem;
    }

    .inline {
      display: flex;
      align-items: center;

      & > *:first-child {
        margin-right: 1rem;
      }
    }
  }
`